import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import Divider from '../components/Divider';
import DynamicVideosWithControls from '../components/DynamicVideosWithControls';

export default function ConceptPage() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Layout>
                <Header />

                <DynamicVideosWithControls
                    titleText={t("The Gather Step Explained")}
                    descriptionText={t("The Gather Step will allow us to be more unpredictable, as it enables us to have one additional option compared to the traditional count.")}
                    videoSrc={"/videos/concept-ENG_Paso_0_Norma.mp4"}
                    borderRadiusVideo={5}
                    posterSrc={"/images/posterGatherStep.jpg"}
                />

                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />

                <Footer />
                <LanguageSwitcher />
            </Layout>
        </>
    );
}
