// colorConstants.js
export const COLORS = {
    blueICB: '#00398F',
    orangeICB: '#DD6F20',
    whiteICB: 'white',
    blackICB: 'black',
    greyICB: '#f4f4f4',
    darkgreyICB: '#adc0d2',
    superdarkgreyICB: '#647c94',
    priceblueICB: '#1c54e4',
    superlightblueICB: '#f4fcfc',
    lightblue1ICB: '#f0f9ff',
    lightblue2ICB: '#e0f7ff',
    superdarkblueICB: '#0c143c',
    blueMUI: '#255ae5',
    lightOrangeICB: '#f6e2d3',
    orangeGradientICB: '#ff7f25'
    // add more colors as needed
};

export const FONT_FAMILY = 'PT Sans, sans-serif';

export const PRICES = {
    free: '0',
    proCoach: '3.99',
    proClub: '3.99',
};

export function getVideoSrc(baseName, language) {
    const lang = language.split('-')[0];
    switch (lang) {
        case 'es':
            return `/videos/${baseName}_ES.mp4`;
        case 'ca':
            return `/videos/${baseName}_ES.mp4`; //todo: add catalan videos
        default:
            return `/videos/${baseName}_EN.mp4`;
    }
}

//to the Login Page
export let app = 'https://app.icoachbasketball.com/';   // assume prod
if (window.location.hostname === 'test.www.icoachbasketball.netmaster.dk') app = 'https://test.app.icoachbasketball.netmaster.dk/'; // test
if (window.location.hostname === 'localhost') app = 'http://localhost:5173/'; // development


//to the SignUp Page
// export let appSignUp = 'https://app.icoachbasketball.com/?q=signup';   // assume prod
// if (window.location.hostname === 'test.www.icoachbasketball.netmaster.dk') appSignUp = 'https://test.app.icoachbasketball.netmaster.dk/?q=signup'; // test
// if (window.location.hostname === 'localhost') appSignUp = 'https://test.app.icoachbasketball.netmaster.dk/?q=signup'; // development

export let appSignUp = app + '?q=signup';   // assume prod
