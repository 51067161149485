import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { Grow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Constantes exportadas para ser usadas en otros archivos
export const DEFAULT_TITLE_TEXT = 'Automated Practices';
export const DEFAULT_DESCRIPTION_TEXT = 'Create automated practices just by filling in the information about your team and find them in your calendar.';
export const DEFAULT_VIDEO_SRC = '/videos/automatedPracticesLandscape_EN.mp4';
export const DEFAULT_POSTER_SRC = '';

// Nueva constante para el borderRadius del video
export const VIDEO_BORDER_RADIUS = 20;

export default function DynamicVideosWithControls({
    titleText = DEFAULT_TITLE_TEXT,
    descriptionText = DEFAULT_DESCRIPTION_TEXT,
    videoSrc = DEFAULT_VIDEO_SRC,
    posterSrc = DEFAULT_POSTER_SRC,
    borderRadiusVideo = VIDEO_BORDER_RADIUS,
}) {
    const { t } = useTranslation();
    const theme = useTheme();

    // Hooks para detectar la visibilidad en el viewport
    const { ref: titleRef, inView: titleInView } = useInView({ triggerOnce: true, threshold: 0.5 });
    const { ref: videoRef, inView: videoInView } = useInView({ triggerOnce: true, threshold: 0.5 });

    return (
        <Box sx={{ textAlign: 'center' }}>
            <Box
                sx={{
                    maxWidth: '800px',
                    margin: 'auto',
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    marginTop: 10,
                }}
            >
                {/* Título animado al aparecer en el viewport */}
                <Grow in={titleInView} timeout={2000}>
                    <Box>
                        <Typography
                            ref={titleRef}
                            sx={{
                                fontFamily: 'var(--font-inter), sans-serif',
                                padding: '10px',
                                fontSize: { xs: '25px', md: '40px' },
                                fontWeight: 600,
                                color: '#FF7720',
                                backgroundSize: '200% 200%',
                                WebkitBackgroundClip: 'text',
                                lineHeight: 1.1,
                                marginTop: '35px',
                            }}
                        >
                            {t(titleText)}
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'var(--font-inter), sans-serif',
                                fontSize: { xs: '16px', md: '20px' },
                                color: 'white',
                            }}
                        >
                            {t(descriptionText)}
                        </Typography>
                    </Box>
                </Grow>

                {/* Video que se reanima al aparecer en pantalla por primera vez */}
                <Grow in={videoInView} timeout={3000} appear>
                    <Box ref={videoRef} sx={{ marginTop: 2, padding: 2 }}>
                        <video
                            style={{
                                borderRadius: borderRadiusVideo,
                                width: '100%',
                                maxWidth: '800px',
                                height: 'auto',
                            }}
                            playsInline
                            controls
                            poster={posterSrc}
                        >
                            <source src={videoSrc} type="video/mp4" />
                            Tu navegador no soporta la etiqueta de video.
                        </video>
                    </Box>
                </Grow>
            </Box>
        </Box>
    );
}