import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import "./i18n.js"
import NotFoundPage from './pages/NotFoundPage.js';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import LandingPage from './pages/LandingPage.js';
import ClubsPage from './pages/ClubsPage.js';
import PricingPage from './pages/PricingPage.js';
import SupportPage from './pages/SupportPage.js';
import AutomatedPracticesPage from './pages/AutomatedPracticesPage.js';
import ManualPracticesPage from './pages/ManualPracticesPage.js';
import DrillsLibraryPage from './pages/DrillsLibraryPage.js';
import PlaysLibraryPage from './pages/PlaysLibraryPage.js';
import PlaybookCreatorPage from './pages/PlaybookCreatorPage.js';
import AttendanceTrackerPage from './pages/AttendanceTrackerPage.js';
import ConceptPage from './pages/ConceptPage.js';
import DrillPage from './pages/DrillPage.js';
import PlayCreatorTutorialPage from './pages/PlayCreatorTutorialPage.js';

function App() {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Routes>


            <Route path="" element={<><Helmet><title>{t('Helmet00')}</title></Helmet><LandingPage /></>} />
            <Route path="/clubs" element={<><Helmet><title>{t('Helmet05')}</title></Helmet><ClubsPage /></>} />
            <Route path="/pricing" element={<><Helmet><title>{t('Helmet01')}</title></Helmet><PricingPage /></>} />
            <Route path="/support" element={<><Helmet><title>{t('Helmet04')}</title></Helmet><SupportPage /></>} />

            {/* FEATURES */}
            <Route path="/automated-practices" element={<><Helmet><title>{t('Helmet06')}</title></Helmet><AutomatedPracticesPage /></>} />
            <Route path="/customized-practices" element={<><Helmet><title>{t('Helmet07')}</title></Helmet><ManualPracticesPage /></>} />
            <Route path="/drills-library" element={<><Helmet><title>{t('Helmet08')}</title></Helmet><DrillsLibraryPage /></>} />
            <Route path="/plays-library" element={<><Helmet><title>{t('Helmet09')}</title></Helmet><PlaysLibraryPage /></>} />
            <Route path="/playbook-creator" element={<><Helmet><title>{t('Helmet10')}</title></Helmet><PlaybookCreatorPage /></>} />
            <Route path="/attendance-tracker" element={<><Helmet><title>{t('Helmet11')}</title></Helmet><AttendanceTrackerPage /></>} />

            {/* TUTORIALS */}
            <Route path="/play-creator-tutorial" element={<><Helmet><title>{t('Tutorial | iCoachBasketball')}</title></Helmet><PlayCreatorTutorialPage /></>} />

            <Route path="*" element={<><Helmet><title>{t('Helmet03')}</title></Helmet><NotFoundPage /></>} /> {/* Ruta para manejar páginas no encontradas */}

            {/* videos */}
            <Route path="/concept-explained" element={<><Helmet><title>{t('The Gather Step | iCoachBasketball')}</title></Helmet><ConceptPage /></>} />
            <Route path="/drill-explained" element={<><Helmet><title>{t('Kobe Bryant Footwork Drill | iCoachBasketball')}</title></Helmet><DrillPage /></>} />

          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;