import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import Divider from '../components/Divider';
import DynamicVideosWithControls from '../components/DynamicVideosWithControls';

export default function DrillPage() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Layout>
                <Header />

                <DynamicVideosWithControls
                    titleText={t("Kobe Bryant Footwork Drill")}
                    descriptionText={t("Inspired by Kobe Bryant's footwork, this drill focuses on improving footwork, ball fakes, spins and balance.")}
                    videoSrc={"/videos/drillKobe.mp4"}
                    borderRadiusVideo={5}
                    posterSrc={"/images/posterKobeDrill.jpg"}
                />
                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />

                <Footer />
                <LanguageSwitcher />
            </Layout>
        </>
    );
}
