import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getVideoSrc } from '../constants/constants';

export default function ScreensCarousel() {
    const { t, i18n } = useTranslation();
    // Lista de videos para el carrusel con títulos
    const videos = [
        { id: 1, src: getVideoSrc('automatedPractices', i18n.language), title: t('ScreensCarousel00') },
        { id: 2, src: getVideoSrc('manualPractices', i18n.language), title: t('ScreensCarousel01') },
        { id: 3, src: '/videos/drillsLibraryP_EN.mp4', title: t('ScreensCarousel02') },
        { id: 4, src: getVideoSrc('playsLibrary', i18n.language), title: t('ScreensCarousel03') },
        { id: 5, src: '/videos/playCreator.mp4', title: t('ScreensCarousel04') }

        // { id: 3, src: getVideoSrc('drillsLibraryP', i18n.language), title: t('ScreensCarousel02') },
        // { id: 4, src: getVideoSrc('playsLibrary', i18n.language), title: t('ScreensCarousel03') },
        // { id: 5, src: getVideoSrc('playCreator', i18n.language), title: t('ScreensCarousel04') }
    ];

    const carouselRef = useRef(null);
    const [showPrev, setShowPrev] = useState(false);
    const [showNext, setShowNext] = useState(true);

    // Nuevo estado para detectar si la página está en la parte superior
    const [atTop, setAtTop] = useState(true);

    // Margen derecho entre videos (aumentado)
    const VIDEO_MARGIN = 32;

    // Actualiza la visibilidad de los botones de navegación
    const updateButtonsVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowPrev(scrollLeft > 0);
            setShowNext(scrollLeft + clientWidth < scrollWidth);
        }
    };

    // Detectar scroll vertical para cambiar el estilo de los videos
    useEffect(() => {
        const handleScroll = () => {
            // Si el scroll vertical es mínimo, consideramos que la pantalla está "all the way up"
            setAtTop(window.scrollY < 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            updateButtonsVisibility();
            carousel.addEventListener('scroll', updateButtonsVisibility);
            return () => {
                carousel.removeEventListener('scroll', updateButtonsVisibility);
            };
        }
    }, []);

    // Función para manejar el fin del video y reiniciarlo
    const handleVideoEnded = (e) => {
        e.target.currentTime = 0;
        e.target.play();
    };

    // Estilos en línea
    const containerStyle = {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '100px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        paddingRight: '2px',
        paddingLeft: '2px',
    };

    const trackStyle = {
        display: 'flex',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        msOverflowStyle: 'none', // Para ocultar flechas en IE y Edge
        scrollbarWidth: 'none',  // Para ocultar la barra de desplazamiento en Firefox
        gap: `${VIDEO_MARGIN}px`, // Espacio horizontal entre videos
    };

    const videoItemStyle = {
        flex: '0 0 auto',
        backgroundColor: '#000',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    };

    const titleStyle = {
        marginBottom: '10px',
        color: 'gray',
        fontSize: '14px',
        textAlign: 'center',
    };

    const buttonStyle = {
        border: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '2rem',
        padding: '10px 15px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        borderRadius: '50%',
    };

    const leftButtonStyle = {
        ...buttonStyle,
        left: '10px',
        display: showPrev ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const rightButtonStyle = {
        ...buttonStyle,
        right: '10px',
        display: showNext ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
    };

    // Ajustamos el estilo de los videos según la posición de scroll vertical (atTop)
    // Cuando esté arriba (atTop = true), los videos son más grandes y más oscuros
    // Cuando esté en medio (atTop = false), los videos son más claros y más pequeños
    const videoStyle = {
        maxHeight: atTop ? '320px' : '280px',
        maxWidth: '100%',
        objectFit: 'cover',
        borderRadius: 7,
        transition: 'all 0.7s ease',
        filter: atTop ? 'brightness(30%)' : 'brightness(100%)',
        transform: atTop ? 'scale(1.05)' : 'scale(1)',
        transformOrigin: 'center center', // Importante para que la escala respete el centro
    };

    return (
        <div style={containerStyle}>

            {/* Carrusel de Videos */}
            <div
                style={trackStyle}
                ref={carouselRef}
                className="carousel-track"
            >
                {videos.map((video) => (
                    <div key={video.id} style={videoItemStyle}>
                        <div style={titleStyle}>{video.title}</div>
                        <video
                            src={video.src}
                            style={videoStyle}
                            playsInline
                            autoPlay
                            muted
                            loop
                            onEnded={handleVideoEnded}
                        />
                    </div>
                ))}
            </div>



            {/* Estilo adicional para ocultar la barra de desplazamiento en navegadores que lo permitan */}
            <style>
                {`
                    .carousel-track::-webkit-scrollbar {
                        display: none;
                    }
                `}
            </style>
        </div>
    );
}