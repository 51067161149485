import * as React from 'react';
import Layout from '../components/Layout';
import PricingCards from '../components/PricingCards';
import OpinionsFromUsers from '../components/OpinionsFromUsers';
import BannerScheduleMeeting from '../components/BannerScheduleMeeting';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LanguageSwitcher from '../components/LanguageSwitcher';
import Box from '@mui/material/Box';

export default function PricingPage() {
  return (
    <Layout>
      <Header />
      <PricingCards />
      <OpinionsFromUsers />
      <Box sx={{ marginTop: { xs: 15, sm: 15 } }}>
        <BannerScheduleMeeting />
      </Box>
      <Footer />
      <LanguageSwitcher />
    </Layout>
  );
}