import React, { useState } from 'react';
import { Grid, Typography, Box, Divider, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { lighten } from '@mui/system';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

const UserOpinionCard = ({ imgSrc, opinionText, userName }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 2,
            maxWidth: '350px',
            margin: 'auto',
            borderRadius: 4,
            border: `1px solid ${lighten('#0c1424', 0.8)}`,
            height: '100%',
            justifyContent: 'space-between',
            backgroundColor: '#ecf4fc',
        }}
    >
        <div>
            <img
                src={imgSrc}
                alt="Foto"
                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
            />
            <Typography sx={{ color: '#848dac', paddingTop: 2, fontSize: { xs: '12px', md: '14px' } }}>
                "{opinionText}"
            </Typography>
        </div>
        <div>
            <Divider
                sx={{
                    borderColor: '#848dac',
                    width: '90%',
                    maxWidth: '1000px',
                    margin: 'auto',
                    height: '1px',
                    paddingTop: 2,
                }}
            />
            <Typography sx={{ color: '#848dac', paddingTop: 2 }}>{userName}</Typography>
        </div>
    </Box>
);

export default function OpinionsFromUsers() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [activeOpinion, setActiveOpinion] = useState(0);

    const opinions = [
        {
            imgSrc: '/images/cristina.png',
            opinionText: t('OpinionsFromUsers02'),
            userName: 'Cristina, CB Sant Antoni',
        },
        {
            imgSrc: '/images/william.png',
            opinionText: t('OpinionsFromUsers03'),
            userName: 'William, BK Amager',
        },
        {
            imgSrc: '/images/peptoni.png',
            opinionText: t('OpinionsFromUsers04'),
            userName: 'Pep Toni, CB Muro',
        },
    ];

    // Navegación circular: si estamos en la última tarjeta, el siguiente mostrará la primera.
    const handleNext = () => {
        setActiveOpinion((activeOpinion + 1) % opinions.length);
    };

    // Para mayor consistencia, el botón de anterior también es circular.
    const handlePrevious = () => {
        setActiveOpinion((activeOpinion - 1 + opinions.length) % opinions.length);
    };

    return (
        <Box
            sx={{
                margin: 'auto',
                backgroundColor: '#ecf4fc',
                backgroundRepeat: 'no-repeat',
                paddingRight: { xs: 0, sm: 3 },
                paddingLeft: { xs: 0, sm: 3 },
                marginTop: { xs: 10, sm: 15 },
                paddingBottom: { xs: 10, sm: 20 },
            }}
        >
            <Box sx={{ maxWidth: '1100px', margin: 'auto', paddingTop: { xs: 5, sm: 10 } }}>
                <Typography
                    sx={{
                        fontFamily: 'var(--font-inter), sans-serif',
                        padding: '10px',
                        fontSize: { xs: '25px', md: '40px' },
                        fontWeight: 'bold',
                        color: '#00398F',
                        backgroundSize: '200% 200%',
                        lineHeight: 1.1,
                    }}
                >
                    <span style={{ color: '#00398F' }}> {t('OpinionsFromUsers00')} </span>
                </Typography>

                {isMobile ? (
                    <Box
                        sx={{
                            mt: 5,
                            marginRight: 3,
                            marginLeft: 3,
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <UserOpinionCard
                            imgSrc={opinions[activeOpinion].imgSrc}
                            opinionText={opinions[activeOpinion].opinionText}
                            userName={opinions[activeOpinion].userName}
                        />
                        <IconButton
                            onClick={handlePrevious}
                            sx={{
                                position: 'absolute',
                                left: '1px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            }}
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                        <IconButton
                            onClick={handleNext}
                            sx={{
                                position: 'absolute',
                                right: '1px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            }}
                        >
                            <ArrowForwardIosRoundedIcon />
                        </IconButton>
                    </Box>
                ) : (
                    <Grid container spacing={2} justifyContent="center" mt={5}>
                        <Grid item xs={12} md={4}>
                            <UserOpinionCard
                                imgSrc="/images/cristina.png"
                                opinionText={t('OpinionsFromUsers02')}
                                userName="Cristina, CB Sant Antoni"
                            />
                        </Grid>

                        <Grid item xs={12} md={4} sx={{ marginTop: { xs: '40px', md: 0 } }}>
                            <UserOpinionCard
                                imgSrc="/images/william.png"
                                opinionText={t('OpinionsFromUsers03')}
                                userName="William, BK Amager"
                            />
                        </Grid>

                        <Grid item xs={12} md={4} sx={{ marginTop: { xs: '40px', md: 0 } }}>
                            <UserOpinionCard
                                imgSrc="/images/peptoni.png"
                                opinionText={t('OpinionsFromUsers04')}
                                userName="Pep Toni, CB Muro"
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Box>
    );
}