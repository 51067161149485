import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import DynamicFeatures from '../components/DynamicFeatures';
import LanguageSwitcher from '../components/LanguageSwitcher';
import DynamicBoxes from '../components/DynamicBoxes';
import { useTranslation } from 'react-i18next';
import Divider from '../components/Divider';
import { Box } from '@mui/material';

export default function AttendanceTrackerPage() {
    const { t } = useTranslation();

    const featuresData = [
        {
            gridSize: 12,
            title: t("AttendanceTracker04"),
            description: t("AttendanceTracker05"),
        },

    ];

    return (
        <>
            <Layout>
                <Header />

                <DynamicFeatures
                    titleText={t("AttendanceTracker00")}
                    descriptionText={t("AttendanceTracker01")}
                    videoSrc="videos/attendanceTracking_EN.mp4"
                    borderRadiusVideo={5}
                />


                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />

                <DynamicBoxes
                    mainTitleOrange={t("AttendanceTracker02")}
                    mainTitleWhite={t("AttendanceTracker03")}
                    features={featuresData}
                />
                <Box sx={{ pr: 4, pl: 4, maxWidth: '850px', margin: 'auto' }}>
                    <img
                        style={{
                            borderRadius: 20,
                            width: '100%',
                            maxWidth: '850px',
                            height: 'auto',
                    }}
                        src={t("images/asistencia.jpeg")}
                        alt="Attendance Tracker"
                    />
                </Box>

                <Footer />
                <LanguageSwitcher />
            </Layout>
        </>
    );
}
