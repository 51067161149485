import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import DynamicFeatures from '../components/DynamicFeatures';
import LanguageSwitcher from '../components/LanguageSwitcher';
import DynamicBoxes from '../components/DynamicBoxes';
import { useTranslation } from 'react-i18next';
import Divider from '../components/Divider';
import { getVideoSrc } from '../constants/constants';

export default function PlaysLibraryPage() {
    const { t, i18n } = useTranslation();

    const featuresData = [
        {
            gridSize: 6,
            title: t("PlaysLibraryPage00"),
            description: t(
                "PlaysLibraryPage01"
            ),
        },
        {
            gridSize: 6,
            title: t("PlaysLibraryPage02"),
            description: t(
                "PlaysLibraryPage03"
            ),
        },
        {
            gridSize: 12,
            title: t("PlaysLibraryPage04"),
            description: t(
                "PlaysLibraryPage05"
            ),
        },
    ];


    return (
        <>
            <Layout>
                <Header />

                <DynamicFeatures
                    titleText={t("PlaysLibraryPage06")}
                    descriptionText={t("PlaysLibraryPage07")}
                    videoSrc={getVideoSrc('playsLibrary', i18n.language)}
                    borderRadiusVideo={5}
                />

                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />

                <DynamicBoxes
                    mainTitleOrange={t("PlaysLibraryPage08")}
                    mainTitleWhite={t("PlaysLibraryPage09")}
                    features={featuresData}
                />

                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />

                <DynamicFeatures
                    titleText={t("PlaysLibraryPage10")}
                    descriptionText={t("PlaysLibraryPage11")}
                    // videoSrc={t("videos/playCreator.mp4")} // TODO: Añadir el video
                    videoSrc={t("videos/playCreator.mp4")}
                    borderRadiusVideo={5}
                />

                <Footer />
                <LanguageSwitcher />
            </Layout>
        </>
    );
}
