import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import DynamicFeatures from '../components/DynamicFeatures';
import LanguageSwitcher from '../components/LanguageSwitcher';
import DynamicBoxes from '../components/DynamicBoxes';
import { useTranslation } from 'react-i18next';
import Divider from '../components/Divider';
import { getVideoSrc } from '../constants/constants';

export default function ManualPracticesPage() {
    const { t, i18n } = useTranslation();

    const featuresData = [
        {
            gridSize: 6,
            title: t("ManualPracticesPage00"),
            description: t(
                "ManualPracticesPage01"
            ),
        },
        {
            gridSize: 6,
            title: t("ManualPracticesPage02"),
            description: t(
                "ManualPracticesPage03"
            ),
        },
        {
            gridSize: 6,
            title: t("ManualPracticesPage04"),
            description: t(
                "ManualPracticesPage05"
            ),
        },
        {
            gridSize: 6,
            title: t("ManualPracticesPage06"),
            description: t(
                "ManualPracticesPage07"
            ),
        },
        {
            gridSize: 12,
            title: t("ManualPracticesPage08"),
            description: t(
                "ManualPracticesPage09"
            ),
        },
    ];


    return (
        <>
            <Layout>
                <Header />

                <DynamicFeatures
                    titleText={t("ManualPracticesPage12")}
                    descriptionText={t("ManualPracticesPage13")}
                    videoSrc={getVideoSrc('manualPractices', i18n.language)}
                    borderRadiusVideo={5}
                />

                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />
                <DynamicBoxes
                    mainTitleOrange={t("ManualPracticesPage10")}
                    mainTitleWhite={t("ManualPracticesPage11")}
                    features={featuresData}
                />
                <Footer />
                <LanguageSwitcher />
            </Layout>
        </>
    );
}
