import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { COLORS, FONT_FAMILY } from '../constants/constants';
import Button from '@mui/material/Button'; // Import the Button component
import { Grid, TextField, Typography, Grow, lighten, darken } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

export const IframeSupport = () => {
    const { t } = useTranslation()
    const form = useRef();
    const [message, setMessage] = useState('');
    const [charCount, setCharCount] = useState(0);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5  // Ajusta según necesidad para cuándo debe activarse la animación
    });

    useEffect(() => {
        setCharCount(message.length);
    }, [message]);

    const sendEmail = (e) => {
        e.preventDefault();

        // Simple validation for empty fields
        if (!form.current.from_name.value.trim() ||
            !form.current.from_email.value.trim()) {
            alert(t('IframeSupport00'));
            return;
        }

        // Simple email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(form.current.from_email.value)) {
            alert(t('IframeSupport01'));
            return;
        }

        emailjs.sendForm('service_60er2i5', 'template_r85kjm7', form.current, 'cDo2OZYTwYI3-SbGV')
            .then((result) => {
                console.log(result.text);
                alert(t('IframeSupport02'));
                form.current.reset(); // Reset the form fields to their original state
            }, (error) => {
                console.log(error.text);
                alert(t('IframeSupport03'));
            });
    };

    const handleMessageChange = (e) => {
        if (e.target.value.length <= 300) {
            setMessage(e.target.value);
            setCharCount(e.target.value.length);
        }
    };

    const fieldData = [
        {
            label: t('IframeSupport04'),
            type: "text",
            name: "from_name",
            placeholder: t('IframeSupport05'),
        },
        {
            label: t('IframeSupport06'),
            type: "email",
            name: "from_email",
            placeholder: t('IframeSupport07'),
        },
    ];

    return (

        <form ref={form} onSubmit={sendEmail}>

            <Grid sx={{ width: '95%', maxWidth: '1000px', margin: 'auto', paddingBottom: 10 }}>

                <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
                    <Grid container justifyContent="center" ref={ref}>

                        <Typography sx={{
                            fontFamily: 'var(--font-inter), sans-serif',
                            fontSize: { xs: '25px', md: '40px' },
                            fontWeight: 600,
                            color: 'transparent',
                            background: '#FF7720',
                            backgroundSize: '200% 200%',
                            WebkitBackgroundClip: 'text',
                            lineHeight: 1.1,
                            marginTop: '70px',
                        }}>
                            {t('IframeSupport08')}
                        </Typography>
                        {/* <Typography sx={{
                            fontSize: { xs: '25px', md: '40px' },
                            color: '#dce4ec',
                            fontWeight: '900',
                            fontFamily: 'Roboto, sans-serif',
                            textAlign: 'center',
                            lineHeight: '1'
                        }}>
                            {t('IframeSupport08')}
                        </Typography> */}
                    </Grid>
                </Grow>

                <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2500}>
                    <Grid container justifyContent="center" ref={ref}>

                        <Typography sx={{
                            // marginTop: '20px',
                            fontFamily: 'var(--font-inter), sans-serif',
                            fontSize: '18px',
                            color: 'white',
                        }}>
                            {t('IframeSupport09')}
                        </Typography>


                    </Grid>
                </Grow>

                <Grid container maxWidth="600px" sx={{ width: { xs: '90%', sm: '600px' }, margin: 'auto', marginTop: 5 }} alignItems="center">
                    {fieldData.map((field, index) => (
                        <Grid item xs={12} sm={6} sx={{ paddingRight: { xs: 0, sm: index % 2 === 0 ? 1 : 0 }, paddingLeft: { xs: 0, sm: index % 2 !== 0 ? 1 : 0 }, paddingBottom: 2 }} key={index}>
                            <Typography sx={{ fontFamily: FONT_FAMILY, color: 'lightgrey' }} >
                                {field.label}
                                <span style={{ color: COLORS.orangeICB }}> *</span>
                            </Typography>
                            <TextField
                                type={field.type}
                                name={field.name}
                                placeholder={field.placeholder}
                                fullWidth
                                InputProps={{
                                    style: { fontSize: '0.875rem', backgroundColor: 'white' }, // Ajusta el tamaño de la fuente del placeholder y establece el color de fondo
                                }}
                                sx={{
                                    '& .MuiInputBase-input': { height: 15, padding: '10px 14px' },
                                    '& .MuiOutlinedInput-root': { borderRadius: '4px', backgroundColor: 'white' }, // Establece el color de fondo del TextField
                                    fontFamily: FONT_FAMILY
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Grid container maxWidth="600px" sx={{ width: { xs: '90%' }, margin: 'auto' }} alignItems="center">
                    <Grid item xs={12} sx={{ paddingBottom: 2 }}>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontFamily: FONT_FAMILY, color: 'lightgrey' }} >
                                {t('IframeSupport10')}
                            </Typography>
                        </Grid>
                        <TextField
                            name="message"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            value={message}
                            onChange={handleMessageChange}
                            placeholder={t('IframeSupport11')}
                            sx={{
                                '& .MuiOutlinedInput-root': { borderRadius: '4px', backgroundColor: COLORS.whiteICB }, // Establece el color de fondo del TextField
                                fontFamily: FONT_FAMILY
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', fontFamily: FONT_FAMILY, color: 'lightgrey' }}>
                        <div>
                            {charCount}/300
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', fontFamily: FONT_FAMILY }}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                height: '33px',
                                textTransform: 'none',
                                // borderRadius: 2,
                                marginTop: '20px',
                                fontWeight: 'bold',
                                backgroundColor: '#FF7720',
                                border: `1px solid ${lighten(COLORS.orangeICB, 0.1)}`,
                                '&:hover': {
                                    backgroundColor: darken(COLORS.orangeICB, 0.1),
                                }
                            }}
                        >
                            {t('IframeSupport12')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form >
    );
};