import React, { useState } from 'react';
import { Box, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { Grow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getVideoSrc } from '../constants/constants';

const FeatureDrillsAndPlaysLibrary = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    // Detectamos si el breakpoint es "xs" (solo para pantallas muy pequeñas)
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    // Estado para controlar el toggle seleccionado
    const [selectedToggle, setSelectedToggle] = useState('auto');

    // Manejador para el cambio de toggle
    const handleToggle = (event, newSelection) => {
        if (newSelection !== null) {
            setSelectedToggle(newSelection);
        }
    };

    // Hook para detectar la visibilidad en el viewport del título
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

    // Hook para detectar la visibilidad en el viewport del contenido dependiente del toggle
    const { ref: contentRef, inView: contentInView } = useInView({ triggerOnce: true, threshold: 0.5 });

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(0.5),
            border: 0,
            height: 30,
            width: 'auto',
            padding: '10 10px',
            '&:not(:first-of-type)': {
                borderRadius: 5,
            },
            '&:first-of-type': {
                borderRadius: 5,
            },
            '&.Mui-selected': {
                backgroundColor: 'transparent',
                color: '#EE7720',
                border: '1px solid #EE7720', // Borde blanco
                textTransform: 'none',
                fontWeight: 'bold',
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#EE7720',
                    border: `1px solid #EE7720`,
                }
            },
            '&:not(.Mui-selected)': {
                color: 'darkgrey',
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#EE7720',
                }
            },
        },
    }));

    // '&:hover': {
    //     backgroundColor: 'transparent',
    //         color: '#EE7720',
    //             border: `1px solid #EE7720`,

    return (
        <Box sx={{ textAlign: 'center' }}>

            <Box
                sx={{
                    maxWidth: '875px',
                    margin: 'auto',
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                {/* Título animado al aparecer en el viewport */}
                <Grow in={inView} timeout={2000}>
                    <div ref={ref}>
                        <Typography
                            sx={{
                                fontFamily: 'var(--font-inter), sans-serif',
                                padding: '10px',
                                fontSize: { xs: '25px', md: '40px' },
                                fontWeight: 600,
                                color: 'transparent',
                                background: 'white',
                                backgroundSize: '200% 200%',
                                WebkitBackgroundClip: 'text',
                                lineHeight: 1.1,
                                marginTop: '35px',
                            }}
                        >
                            <span style={{ color: '#FF7720' }}> {t('FeatureDrillsAndPlaysLibrary00')} </span><br />
                            <span style={{ color: 'white' }}>
                                {t('FeatureDrillsAndPlaysLibrary01')}
                            </span>
                        </Typography>
                    </div>
                </Grow>

                <StyledToggleButtonGroup
                    color="primary"
                    value={selectedToggle}
                    exclusive
                    onChange={handleToggle}
                    aria-label="Feature toggle"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                >
                    <ToggleButton value="auto" aria-label="auto">
                        {t('FeatureDrillsAndPlaysLibrary02')}
                    </ToggleButton>
                    <ToggleButton value="manual" aria-label="manual">
                        {t('FeatureDrillsAndPlaysLibrary03')}
                    </ToggleButton>
                </StyledToggleButtonGroup>

                {/* Contenedor para el contenido dependiente del toggle, con ref para detectar su visibilidad */}
                <Box ref={contentRef}>
                    {/* Video que se reanima al cambiar el toggle o al aparecer en pantalla por primera vez */}
                    <Grow key={selectedToggle + "-video"} in={contentInView} timeout={3000} appear>
                        <Box sx={{ marginTop: 2, padding: 2 }}>
                            <video
                                style={{
                                    borderRadius: 4,
                                    width: '100%',
                                    maxWidth: '800px',
                                    height: 'auto',
                                }}
                                autoPlay
                                muted
                                loop
                                playsInline
                            >
                                <source
                                    src={
                                        selectedToggle === 'auto'
                                            ? getVideoSrc('drillsLibraryL', i18n.language)
                                            :
                                            //getVideoSrc('playsLibrary', i18n.language)
                                            '/videos/playsLibrary_EN.mp4'
                                    }
                                    type="video/mp4"
                                />
                                Tu navegador no soporta la etiqueta de video.
                            </video>
                        </Box>
                    </Grow>
                </Box>
            </Box>
        </Box>
    );
};

export default FeatureDrillsAndPlaysLibrary;