import * as React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../constants/constants';
import { lighten, darken } from '@mui/system';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
export default function NotFoundPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Layout>
      <Header />
      <Box sx={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Typography variant="h4" sx={{ mb: 2, color: '#dce4ec' }}>
          {t('NotFoundPage00')}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#98a7b0' }}>
          {t('NotFoundPage01')}
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/')}
          sx={{
            height: '33px',
            textTransform: 'none',
            borderRadius: 2,
            marginTop: 3,
            backgroundColor: COLORS.blueICB, // Color de fondo
            border: `1px solid ${lighten(COLORS.blueICB, 0.1)}`, // Borde
            '&:hover': {
              backgroundColor: darken(COLORS.blueICB, 0.1), // Color de fondo al pasar el ratón
            }
          }}
        >
          {t('NotFoundPage02')}
        </Button>
      </Box>
      <Footer />
      <LanguageSwitcher />
    </Layout>
  );
}