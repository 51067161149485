import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { app, appSignUp, COLORS } from '../constants/constants';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import { useTheme, useMediaQuery } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Collapse from '@mui/material/Collapse';

export default function Header() {
    const [scrolled, setScrolled] = useState(false);
    const { t } = useTranslation();
    const headerRef = useRef(null);
    const popoverRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => setScrolled(window.scrollY > 0);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    // Nueva variable para detectar pantallas sm o menos
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    // Mobile menu state and handlers
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuOpen = useCallback((event) => setAnchorEl(event.currentTarget), []);
    const handleMenuClose = useCallback(() => setAnchorEl(null), []);

    // Toggle Features submenu (mobile)
    const [featuresOpen, setFeaturesOpen] = useState(false);
    const toggleFeatures = useCallback(() => setFeaturesOpen((prev) => !prev), []);

    // Desktop popover state and handler (Features)
    const [anchorElHover, setAnchorElHover] = useState(null);
    const handlePopoverOpenHover = useCallback((event) => {
        setAnchorElHover(event.currentTarget);
    }, []);

    // Close desktop popover if the mouse leaves the header and popover area
    useEffect(() => {
        const handleMouseMove = (e) => {
            const headerRect = headerRef.current?.getBoundingClientRect();
            const popoverRect = popoverRef.current?.getBoundingClientRect();
            const inHeader =
                headerRect &&
                e.clientX >= headerRect.left &&
                e.clientX <= headerRect.right &&
                e.clientY >= headerRect.top &&
                e.clientY <= headerRect.bottom;
            const inPopover =
                popoverRect &&
                e.clientX >= popoverRect.left &&
                e.clientX <= popoverRect.right &&
                e.clientY >= popoverRect.top &&
                e.clientY <= popoverRect.bottom;
            if (!inHeader && !inPopover) {
                setAnchorElHover(null);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => document.removeEventListener('mousemove', handleMouseMove);
    }, []);

    // Navigation for desktop popover
    const handleNavigate = useCallback((href) => {
        window.location.href = href;
        setAnchorElHover(null);
    }, []);

    // Reusable navigation function for mobile buttons
    const handleMobileNavClick = useCallback(
        (href) => {
            handleMenuClose();
            window.location.href = href;
        },
        [handleMenuClose]
    );

    // List of feature items
    const featureItems = useMemo(
        () => [
            { label: t('Header05'), href: '/automated-practices' },
            { label: t('Header06'), href: '/customized-practices' },
            { label: t('Header07'), href: '/drills-library' },
            { label: t('Header08'), href: '/plays-library' },
            { label: t('Header09'), href: '/playbook-creator' },
            { label: t('Header10'), href: '/attendance-tracker' },
        ],
        []
    );

    // Styles
    const navButtonSx = {
        textTransform: 'none',
        fontWeight: 'bold',
        color: 'white',
        '&:hover': { color: '#EE7720' },
    };

    const mobileButtonSx = {
        textTransform: 'none',
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        fontWeight: 'bold',
        color: 'white',
        '&:hover': { color: '#EE7720', border: '1px solid #EE7720' },
    };

    const desktopButtonBaseSx = {
        height: '33px',
        textTransform: 'none',
        borderRadius: 2,
        backgroundColor: 'transparent',
        fontWeight: 'bold',
        color: 'white',
        boxShadow: 'none',
    };

    const appBarSx = {
        backgroundColor: scrolled ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
        backdropFilter: scrolled ? 'blur(10px)' : 'none',
        WebkitBackdropFilter: scrolled ? 'blur(10px)' : 'none',
        boxShadow: 'none',
    };

    const navButtonPopupSx = {
        textTransform: 'none',
        height: '25px',
        fontSize: '14px',
        color: 'white',
        backgroundColor: 'transparent',
        '&:hover': {
            color: COLORS.orangeICB,
            backgroundColor: 'transparent',
        },
    };

    const navButtonPopupLandscapeSx = {
        textTransform: 'none',
        height: '25px',
        fontSize: '14px',
        color: 'black',
        backgroundColor: 'transparent',
        '&:hover': {
            color: COLORS.orangeICB,
            backgroundColor: 'transparent',
        },
    };

    return (
        <>
            <AppBar position="fixed" sx={appBarSx}>
                {/* Añadir disableGutters si es una pantalla sm o menor */}
                <Container maxWidth="xl" disableGutters={isMobile}>
                    <Toolbar ref={headerRef} sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                        {/* Sección izquierda: Logo y nombre */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Link to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                <img
                                    src="/images/icbWhite.svg"
                                    alt="iCoachBasketball"
                                    style={{ padding: '10px', width: '30px', height: 'auto' }}
                                />
                            </Link>
                            <Typography
                                onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                    window.location.href = '/';
                                }}
                                sx={{
                                    cursor: 'pointer',
                                    ml: 1,
                                    fontWeight: 700,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                i<span style={{ color: '#EE7720' }}>Coach</span>Basketball
                            </Typography>
                        </Box>

                        {/* Sección central (solo escritorio): Botones de navegación */}
                        {!isMobile && (
                            <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Button
                                        endIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: '12px' }} />}
                                        variant="text"
                                        onMouseEnter={handlePopoverOpenHover}
                                        sx={navButtonSx}
                                    >
                                        {t('Header02')}
                                    </Button>
                                    <Button variant="text" onClick={() => (window.location.href = '/pricing')} sx={navButtonSx}>
                                        {t('Header03')}
                                    </Button>
                                    <Button variant="text" onClick={() => (window.location.href = '/clubs')} sx={navButtonSx}>
                                        {t('Header04')}
                                    </Button>
                                </Box>
                            </Box>
                        )}

                        {/* Sección derecha: Icono de menú en móvil o botones de acción en escritorio */}
                        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', mr: { xs: 1, md: 2 } }}>
                            {isMobile ? (
                                <IconButton onClick={anchorEl ? handleMenuClose : handleMenuOpen} sx={{ color: 'white' }}>
                                    {anchorEl ? (
                                        <CloseIcon sx={{ transition: 'transform 0.3s', transform: 'rotate(0deg)' }} />
                                    ) : (
                                        <MenuIcon sx={{ transition: 'transform 0.3s', transform: 'rotate(0deg)' }} />
                                    )}
                                </IconButton>
                            ) : (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => (window.location.href = app)}
                                        sx={{
                                            ...desktopButtonBaseSx,
                                            '&:hover': { backgroundColor: 'transparent', color: '#EE7720' },
                                        }}
                                    >
                                        {t('Header00')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => (window.location.href = appSignUp)}
                                        sx={{
                                            ...desktopButtonBaseSx,
                                            border: '1px solid white',
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                                color: '#EE7720',
                                                border: '1px solid #EE7720',
                                            },
                                        }}
                                    >
                                        {t('Header01')}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            {/* Mobile menu Popover (without slide transition) */}
            {isMobile && (
                <Popover
                    open={Boolean(anchorEl)}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 56 }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={handleMenuClose}
                    PaperProps={{
                        sx: { p: 2, borderRadius: 0, boxShadow: 3, bgcolor: 'black', width: '100%' },
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                            fullWidth
                            size='small'
                            variant="text"
                            onClick={toggleFeatures}
                            sx={{ ...mobileButtonSx, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                        >
                            {t('Header02')}
                            <KeyboardArrowDownRoundedIcon
                                size='small'
                                sx={{
                                    ml: 1,
                                    transform: featuresOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s',
                                }}
                            />
                        </Button>
                        <Collapse in={featuresOpen} timeout="auto" unmountOnExit>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, ml: 2 }}>
                                {featureItems.map((item) => (
                                    <Button
                                        size='small'
                                        key={item.href}
                                        fullWidth
                                        variant="text"
                                        onClick={() => handleMobileNavClick(item.href)}
                                        sx={{ ...navButtonPopupSx, justifyContent: 'flex-start' }}
                                    >
                                        {item.label}
                                    </Button>
                                ))}
                            </Box>
                        </Collapse>
                        <Button
                            size='small'
                            fullWidth
                            variant="text"
                            onClick={() => handleMobileNavClick('/pricing')}
                            sx={{ ...mobileButtonSx, justifyContent: 'flex-start' }}
                        >
                            {t('Header03')}
                        </Button>
                        <Button
                            size='small'
                            fullWidth
                            variant="text"
                            onClick={() => handleMobileNavClick('/clubs')}
                            sx={{ ...mobileButtonSx, justifyContent: 'flex-start' }}
                        >
                            {t('Header04')}
                        </Button>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => handleMobileNavClick(app)}
                            sx={{
                                textTransform: 'none',
                                color: 'white',
                                fontWeight: 'bold',
                                border: '1px solid white',
                                '&:hover': { backgroundColor: 'transparent', color: '#EE7720', border: '1px solid #EE7720' },
                            }}
                        >
                            {t('Header00')}
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleMobileNavClick(appSignUp)}
                            sx={{
                                textTransform: 'none',
                                color: 'white',
                                backgroundColor: '#FF7720',
                                fontWeight: 'bold',
                                border: '1px solid #FF7720',
                                '&:hover': { backgroundColor: 'transparent', color: '#EE7720', border: '1px solid #EE7720' },
                            }}
                        >
                            {t('Header01')}
                        </Button>
                    </Box>
                </Popover>
            )}

            {/* Desktop Features Popover */}
            {!isMobile && (
                <Popover
                    open={Boolean(anchorElHover)}
                    anchorEl={anchorElHover}
                    onClose={() => setAnchorElHover(null)}
                    disableRestoreFocus
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    PaperProps={{
                        ref: popoverRef,
                        sx: { p: 2, borderRadius: 2, boxShadow: 3, mt: 1 },
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
                        <Typography sx={{ fontWeight: 'bold', color: COLORS.orangeICB, textAlign: 'center', width: '100%' }}>
                            {t('Header02')}
                        </Typography>
                        {featureItems.map((item) => (
                            <Button
                                key={item.href}
                                variant="text"
                                onClick={() => handleNavigate(item.href)}
                                sx={navButtonPopupLandscapeSx}
                            >
                                {item.label}
                            </Button>
                        ))}
                    </Box>
                </Popover>
            )}
        </>
    );
}