import * as React from 'react';
import { Typography, Box, Button, Grid, Grow } from '@mui/material';
import { COLORS, appSignUp } from '../constants/constants';
import { darken, lighten } from '@mui/system';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

function Intro() {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const { t } = useTranslation();
  return (
    // Se usa position: 'relative' para que el background absoluto se posicione correctamente
    <Box
      sx={{
        paddingTop: { xs: 10, md: 17 },
        maxWidth: '1250px',
        margin: 'auto',
        width: '95%',
        position: 'relative',
      }}
    >
      {/* Fondo animado con formas variadas */}
      <Grow in={inView} timeout={2000}>
        <Typography
          ref={ref}
          sx={{
            fontFamily: 'var(--font-inter), sans-serif',
            fontSize: { xs: '45px', md: '75px' },
            fontWeight: 600,
            color: 'white',
            backgroundSize: '200% 200%',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            lineHeight: 1.1,
          }}
        >
          <span style={{ color: '#FF7720' }}> {t('Intro00')} </span><br />
          <span style={{ color: 'white' }}>
            {t('Intro01')}
          </span>
        </Typography>
      </Grow>

      <Box sx={{ maxWidth: '750px', margin: 'auto' }}>
        <Grow in={inView} timeout={2000}>
          <Typography
            ref={ref}
            sx={{
              marginTop: { xs: 3, md: 3 },
              fontFamily: 'var(--font-inter), sans-serif',
              fontSize: '18px',
              color: 'lightgray',
              lineHeight: { xs: 1.2, md: 1.7 },
            }}
          >
            {t('Intro02')}
          </Typography>
        </Grow>
      </Box>

      <Grow in={inView} timeout={2000} ref={ref}>
        <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
          <Grid item xs={12} sm="auto">
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                window.location.href = appSignUp;
              }}
              sx={{
                maxWidth: '300px',
                textTransform: 'none',
                height: '45px',
                borderRadius: 2,
                fontWeight: 'bold',
                backgroundColor: '#EE7720',
                border: `1px solid ${lighten(COLORS.orangeICB, 0.1)}`,
                '&:hover': {
                  backgroundColor: darken('#EE7720', 0.1),
                },
              }}
            >
              {t('Intro03')}
            </Button>
            <Typography sx={{ fontSize: '12px', color: 'gray', marginTop: '10px' }}>
              {t('Intro04')}
            </Typography>
          </Grid>
        </Grid>
      </Grow>
    </Box>
  );
}

export default Intro;