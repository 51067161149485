import * as React from 'react';
import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { darken } from '@mui/material/styles';
import { COLORS } from '../constants/constants';

const languages = [
  { code: 'en', flag: '/images/banderaUK.png', initials: 'EN' },
  { code: 'es', flag: '/images/banderaES.png', initials: 'ES' },
  { code: 'ca', flag: '/images/banderaCA.png', initials: 'CA' }, // Para catalán se utiliza una imagen.
];

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // Abre el menú para seleccionar el idioma.
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Cambia el idioma y recarga la página para actualizar todos los elementos.
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setAnchorEl(null);
    window.location.reload();
  };

  // Función auxiliar para renderizar la bandera de cada idioma.
  const renderFlag = (lang) => {
    return (
      <div
        style={{
          width: '18px',
          height: '18px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #fff',
          borderRadius: '50%',
          overflow: 'hidden',
        }}
      >
        <img
          src={lang.flag}
          alt={`Bandera de ${lang.initials}`}
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </div>
    );
  };

  // Se utiliza el idioma actual detectado por i18next (mediante el detector del navegador).
  const currentLanguage = languages.find(
    (lang) => lang.code === i18n.language.split('-')[0]
  ) || languages[0];

  return (
    <div>
      <Fab
        onClick={handleClick}
        color="primary"
        variant="extended"
        aria-label="cambiar idioma"
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'fixed',
          bottom: 0,
          right: 16,
          borderRadius: 1,
          padding: '0 16px',
          height: '40px',
          minHeight: '40px',
          fontWeight: 'bold',
          backgroundColor: '#FF7720',
          '&:hover': {
            backgroundColor: darken('#FF7720', 0.1),
          },
        }}
      >
        <span style={{ marginRight: 8 }}>{renderFlag(currentLanguage)}</span>
        {currentLanguage.initials}
      </Fab>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            color: 'text.primary',
          },
        }}
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.code}
            onClick={() => changeLanguage(lang.code)}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            {renderFlag(lang)}
            <span>{lang.initials}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}