import { useTranslation } from 'react-i18next';
import { Box, lighten, darken } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';

export default function BannerScheduleMeeting() {
  const { t } = useTranslation();

  return (
    <Box sx={{ marginTop: { xs: 0, md: 15 }, padding: { xs: 0, md: 2 } }}>
      <Box
        sx={{
          background: '#FF7720',
          borderRadius: { xs: 0, md: 3 },
          maxWidth: '1000px',
          margin: 'auto',
          padding: 5, // Espaciado interno para el contenido
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* Área izquierda: título y descripción */}
          <Box sx={{ textAlign: 'left', width: { xs: '100%', md: '70%' } }}>
            <Typography
              sx={{
                fontFamily: 'var(--font-inter), sans-serif',
                fontSize: { xs: '20px', md: '30px' },
                fontWeight: 600,
                color: 'white',
                backgroundSize: '200% 200%',
                WebkitBackgroundClip: 'text',
                lineHeight: 1.1,
              }}
            >
              {t('BannerScheduleHubspot00')}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'var(--font-inter), sans-serif',
                fontSize: { xs: '14px', md: '16px' },
                color: 'white',
                marginTop: 1,
              }}
            >
              {t('BannerScheduleHubspot01')}
            </Typography>
          </Box>

          {/* Área derecha: botón */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              marginTop: { xs: '20px', md: '0' },
            }}
          >
            <Button
              variant="outlined"
              endIcon={<ArrowOutwardRoundedIcon />}
              onClick={() => {
                window.location.href = '/clubs';
              }}
              sx={{
                height: '33px',
                textTransform: 'none',
                borderRadius: 2,
                color: 'white',
                fontWeight: 'bold',
                border: '1px solid white',
                '&:hover': {
                  backgroundColor: darken('#FF7720', 0.1),
                  border: '1px solid white',
                },
              }}
            >
              {t('BannerScheduleMeeting00')}

            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};