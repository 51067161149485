import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import DynamicFeatures from '../components/DynamicFeatures';
import LanguageSwitcher from '../components/LanguageSwitcher';
import DynamicBoxes from '../components/DynamicBoxes';
import { useTranslation } from 'react-i18next';
import Divider from '../components/Divider';

export default function AutomatedPracticesPage() {
    const { t } = useTranslation();

    const featuresData = [
        {
            gridSize: 6,
            title: t("PlaybookCreatorPage00"),
            description: t(
                "PlaybookCreatorPage01"
            ),
        },
        {
            gridSize: 6,
            title: t("PlaybookCreatorPage02"),
            description: t(
                "PlaybookCreatorPage03"
            ),
        },
        {
            gridSize: 12,
            title: t("PlaybookCreatorPage04"),
            description: t(
                "PlaybookCreatorPage05"
            ),
        },
    ];



    return (
        <>
            <Layout>
                <Header />

                <DynamicFeatures
                    titleText={t("PlaybookCreatorPage06")}
                    descriptionText={t("PlaybookCreatorPage07")}
                    videoSrc={t("videos/createPlaybook_ES.mp4")}
                    borderRadiusVideo={5}
                />

                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />

                <DynamicBoxes
                    mainTitleOrange={t("PlaybookCreatorPage08")}
                    mainTitleWhite={t("PlaybookCreatorPage09")}
                    features={featuresData}
                />

                <Footer />

                <LanguageSwitcher />
            </Layout>
        </>
    );
}
