import * as React from 'react';
import { Box } from '@mui/material';
import ScreensCarousel from '../components/ScreensCarousel';
import FeaturePracticesAutoAndManual from '../components/FeaturePracticesAutoAndManual';
import Header from '../components/Header';
import LanguageSwitcher from '../components/LanguageSwitcher';
import FeatureDrillsAndPlaysLibrary from '../components/FeatureDrillsAndPlaysLibrary';
import DynamicBoxes from '../components/DynamicBoxes';
import Layout from '../components/Layout';
import Intro from '../components/Intro';
import Carousel from '../components/Carousel';
import InstallApp from '../components/InstallApp';
import OpinionsFromUsers from '../components/OpinionsFromUsers';
import BannerScheduleMeeting from '../components/BannerScheduleMeeting';
import Footer from '../components/Footer';
import Divider from '../components/Divider';
import { useTranslation } from 'react-i18next';

export default function LandingPage() {
    const { t } = useTranslation();

    const featuresData = [
        {
            gridSize: 6,
            title: t("MoreFeatures02"),
            description: t("MoreFeatures03"),
            link: "/drills-library",   
        },
        {
            gridSize: 6,
            title: t("MoreFeatures04"),
            description: t("MoreFeatures05"),
            link: "/plays-library",
        },
        {
            gridSize: 12,
            title: t("MoreFeatures06"),
            description: t("MoreFeatures07"),
            link: "/playbook-creator",
        },
    ];

    return (
        <>
            <Layout>
                <Header />
                <Intro />
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <ScreensCarousel />
                </Box>
                <Divider marginTop={{ xs: 10, sm: 15 }} marginBottom={0} />
                <Carousel />
                <Divider marginTop={{ xs: 0, sm: 2 }} marginBottom={{ xs: 0, sm: 15 }} />
                <FeaturePracticesAutoAndManual />
                <Divider marginTop={{ xs: 5, sm: 15 }} marginBottom={{ xs: 5, sm: 10 }} />

                <DynamicBoxes
                    mainTitleOrange={t("MoreFeatures00")}
                    mainTitleWhite={t("MoreFeatures01")}
                    features={featuresData}
                />

                <Divider marginTop={{ xs: 5, sm: 15 }} marginBottom={{ xs: 5, sm: 5 }} />
                <InstallApp />
                <Divider marginTop={{ xs: 5, sm: 5 }} marginBottom={{ xs: 5, sm: 15 }} />
                <FeatureDrillsAndPlaysLibrary />
                <OpinionsFromUsers />
                <Box sx={{ marginTop: { xs: 15, sm: 15 } }}>
                    <BannerScheduleMeeting />
                </Box>
                <Footer />
                <LanguageSwitcher />
            </Layout>
        </>
    );
}
