import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import Divider from '../components/Divider';
import DynamicVideosWithControls from '../components/DynamicVideosWithControls';

export default function PlayCreatorTutorialPage() {
    const { t } = useTranslation();

    return (
        <>
            <Layout>
                <Header />

                <DynamicVideosWithControls
                    titleText={t("PlayCreatorTutorialPage00")}
                    descriptionText={t("PlayCreatorTutorialPage01")}
                    posterSrc={t("images/posterPlayCreatorTutorial_ES.jpg")}
                    videoSrc={t("videos/tutorial_PlayCreator_ES.mp4")}
                    borderRadiusVideo={5}
                />

                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />

                <Footer />

                <LanguageSwitcher />
            </Layout>
        </>
    );
}
