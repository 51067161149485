import React from 'react';
import { Box } from '@mui/material';
import { lighten } from '@mui/system';
export default function Divider({ marginTop = marginTop, marginBottom = marginBottom }) {
    return (
        <Box sx={{
            height: '1px',
            width: '90%',
            background: `linear-gradient(to right, #000000, ${lighten('#0c1424', 0.5)}, #000000)`,
            margin: 'auto',
            marginTop: marginTop,
            marginBottom: marginBottom
        }} />
    );
}
