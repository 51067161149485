import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import DynamicFeatures from '../components/DynamicFeatures';
import LanguageSwitcher from '../components/LanguageSwitcher';
import DynamicBoxes from '../components/DynamicBoxes';
import { useTranslation } from 'react-i18next';
import Divider from '../components/Divider';
import { getVideoSrc } from '../constants/constants';

export default function DrillsLibraryPage() {
    const { t, i18n } = useTranslation();

    const featuresData = [
        {
            gridSize: 6,
            title: t("DrillsLibraryPage00"),
            description: t(
                "DrillsLibraryPage01"
            ),
        },
        {
            gridSize: 6,
            title: t("DrillsLibraryPage02"),
            description: t(
                "DrillsLibraryPage03"
            ),
        },
        {
            gridSize: 12,
            title: t("DrillsLibraryPage04"),
            description: t(
                "DrillsLibraryPage05"
            ),
        },
    ];


    return (
        <>
            <Layout>
                <Header />

                <DynamicFeatures
                    titleText={t("DrillsLibraryPage06")}
                    descriptionText={t("DrillsLibraryPage07")}
                    videoSrc={getVideoSrc('drillsLibraryL', i18n.language)}
                    borderRadiusVideo={5}
                />

                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />

                <DynamicBoxes
                    mainTitleOrange={t("DrillsLibraryPage08")}
                    mainTitleWhite={t("DrillsLibraryPage09")}
                    features={featuresData}
                />

                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />

                <DynamicFeatures
                    titleText={t("DrillsLibraryPage10")}
                    descriptionText={t("DrillsLibraryPage11")}
                    videoSrc={getVideoSrc('createDrills', i18n.language)}
                    borderRadiusVideo={5}
                />

                <Footer />
                <LanguageSwitcher />
            </Layout>
        </>
    );
}
