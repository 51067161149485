import * as React from 'react';
import Layout from '../components/Layout';
import OpinionsFromUsers from '../components/OpinionsFromUsers';
import Header from '../components/Header';
import IframeMeeting from '../components/IframeMeeting';
import Footer from '../components/Footer';
import Carousel from '../components/Carousel';
import LanguageSwitcher from '../components/LanguageSwitcher';
import Divider from '../components/Divider';
import DynamicBoxes from '../components/DynamicBoxes';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import TextDescriptionSideImage from '../components/TextDescriptionSideImage';
import { getVideoSrc } from '../constants/constants';

export default function ClubsPage() {
  const { t, i18n } = useTranslation();

  const featuresData = [
    {
      gridSize: 6,
      title: t("ClubsPage00"),
      description: t("ClubsPage01"),
    },
    {
      gridSize: 6,
      title: t("ClubsPage02"),
      description: t("ClubsPage03"),
    },
    {
      gridSize: 12,
      title: t("ClubsPage04"),
      description: t("ClubsPage05"),
    },
    {
      gridSize: 12,
      title: t("ClubsPage06"),
      description: t("ClubsPage07"),
    },
  ];

  return (
    <Layout>
      <Header />
      <Box sx={{ paddingTop: { xs: 5, sm: 20 } }} />

      <TextDescriptionSideImage
        titleText={t("ClubsPage08")}
        descriptionText={t("ClubsPage09")}
        videoSrc={getVideoSrc('clubsCalendar', i18n.language)}
      />

      <Divider marginTop={{ xs: 5, sm: 10 }} marginBottom={{ xs: 5, sm: 10 }} />

      <DynamicBoxes
        mainTitleOrange={t("ClubsPage10")}
        mainTitleWhite={t("ClubsPage11")}
        features={featuresData} />

      <Divider marginTop={{ xs: 5, sm: 5 }} marginBottom={10} />

      <div id="igrameeting">
        <IframeMeeting />
      </div>

      <Divider marginTop={{ xs: 10, sm: 15 }} marginBottom={0} />
      <Carousel />
      <Divider marginTop={{ xs: 0, sm: 2 }} marginBottom={{ xs: 0, sm: 15 }} />
      <OpinionsFromUsers />
      <Footer />
      <LanguageSwitcher />
    </Layout>
  );
}