import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import DynamicFeatures from '../components/DynamicFeatures';
import LanguageSwitcher from '../components/LanguageSwitcher';
import DynamicBoxes from '../components/DynamicBoxes';
import { useTranslation } from 'react-i18next';
import Divider from '../components/Divider';
import { getVideoSrc } from '../constants/constants';

export default function AutomatedPracticesPage() {
    const { t, i18n } = useTranslation();

    const featuresData = [
        {
            gridSize: 6,
            title: t("AutomatedPracticesPage00"),
            description: t(
                "AutomatedPracticesPage01"
            ),
        },
        {
            gridSize: 6,
            title: t("AutomatedPracticesPage02"),
            description: t(
                "AutomatedPracticesPage03"
            ),
        },
        {
            gridSize: 4,
            title: t("AutomatedPracticesPage04"),
            description: t(
                "AutomatedPracticesPage05"
            ),
        },
        {
            gridSize: 4,
            title: t("AutomatedPracticesPage06"),
            description: t(
                "AutomatedPracticesPage07"
            ),
        },
        {
            gridSize: 4,
            title: t("AutomatedPracticesPage08"),
            description: t(
                "AutomatedPracticesPage09"
            ),
        },
        {
            gridSize: 12,
            title: t("AutomatedPracticesPage12"),
            description: t("AutomatedPracticesPage13"
            ),
        },
        {
            gridSize: 12,
            title: t("AutomatedPracticesPage14"),
            description: t("AutomatedPracticesPage15"),
        },
    ];



    return (
        <>
            <Layout>
                <Header />

                <DynamicFeatures
                    titleText={t("AutomatedPracticesPage16")}
                    descriptionText={t("AutomatedPracticesPage17")}
                    videoSrc={getVideoSrc('automatedPractices', i18n.language)}
                />


                <Divider marginTop={{ xs: 10, sm: 10 }} marginBottom={5} />
                <DynamicBoxes
                    mainTitleOrange={t("AutomatedPracticesPage18")}
                    mainTitleWhite={t("AutomatedPracticesPage19")}
                    features={featuresData}
                />
                <Footer />
                <LanguageSwitcher />
            </Layout>
        </>
    );
}
