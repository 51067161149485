import { Fragment } from 'react';
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../constants/constants';
import { Grow } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { Box, lighten } from '@mui/material';
import { Typography } from '@mui/material';

let carouselImages = [
    //'andorra.white.png',
    'bka.white.png',
    'cbmuro.white.png',
    'cancanto.white.png',
    'cbzamora.white.png',
    'realejos.white.png',
    'santantoni.white.png',
    'sapobla.white.png',
    'tecla.white.png',
    'paterna.white.png',
    'escastell.white.png',
    'manises.white.png',

    //'andorra.white.png',
    'bka.white.png',
    'cbmuro.white.png',
    'cancanto.white.png',
    'cbzamora.white.png',
    'realejos.white.png',
    'santantoni.white.png',
    'sapobla.white.png',
    'tecla.white.png',
    'paterna.white.png',
    'escastell.white.png',
    'manises.white.png',

    //'andorra.white.png',
    'bka.white.png',
    'cbmuro.white.png',
    'cancanto.white.png',
    'cbzamora.white.png',
    'realejos.white.png',
    'santantoni.white.png',
    'sapobla.white.png',
    'tecla.white.png',
    'paterna.white.png',
    'escastell.white.png',
    'manises.white.png',
];

export default function Carousel({ color1 = COLORS.whiteICB, color2 = COLORS.orangeICB, color3 = COLORS.orangeICB }) {
    const { t } = useTranslation();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5
    });

    return (
        <div style={{
            width: '100%',
            overflow: 'hidden',
            paddingBottom: 25,
            //backgroundColor: COLORS.blueICB,
        }}>

            {/* Texto encima del carrusel */}
            {/* <div style={{ textAlign: 'center', fontFamily: FONT_FAMILY }}>
                    <h4 style={{ color: COLORS.whiteICB }}>{t('Carousel00')}</h4>
                </div> */}
            <Typography sx={{
                fontFamily: 'var(--font-inter), sans-serif',
                fontSize: { xs: '13px', md: '16px' },
                // fontWeight: 600,
                color: 'gray',
                // background: 'linear-gradient(90deg, #99c2e0,#b3d1ec,#cce0f7,#b3d1ec,#99c2e0)',
                backgroundSize: '200% 200%',
                WebkitBackgroundClip: 'text',
                lineHeight: 1.1,
                marginTop: '30px',
            }}>
                {t('Carousel00')}
            </Typography>


            <div style={{ height: '65px', marginTop: 40 }} ref={ref}>
                <Marquee speed={80}>
                    {
                        carouselImages.map((item, index) => (
                            <Fragment key={index}>

                                <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
                                    {/* Añadir prop 'alt' a los elementos img */}
                                    <img src={`/carousel/${item}`} alt="" height={65} width={'auto'} style={{ marginRight: '100px', marginBottom: '30px' }} />
                                </Grow>
                            </Fragment>
                        ))
                    }
                </Marquee>
            </div>
        </div>
    );
};