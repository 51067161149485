import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { Grow } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import { darken } from '@mui/material/styles';

export default function TextDescriptionSideVideo({
    titleText,
    descriptionText,
    videoSrc,
    imageSrc,
    borderRadiusVideo = 10,
}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    // Hooks para animar al aparecer en el viewport
    const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true, threshold: 0.5 });
    const { ref: videoRef, inView: videoInView } = useInView({ triggerOnce: true, threshold: 0.5 });

    return (
        <Grid
            container
            sx={{
                maxWidth: '1350px',
                margin: 'auto',
                marginTop: 5,
                padding: 2,
            }}
        >
            {/* Lado izquierdo: Título y descripción */}
            <Grid item xs={12} md={7}>
                <Grow in={textInView} timeout={2000}>
                    <Box ref={textRef} sx={{ padding: 2, textAlign: isSmallScreen ? 'center' : 'left' }}>
                        <Typography
                            component="h2"
                            sx={{
                                fontSize: { xs: '30px', md: '60px' },
                                fontWeight: 600,
                                color: 'white',
                                marginBottom: 2,
                                lineHeight: 1.2,
                            }}
                        >
                            {t(titleText)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: '16px', md: '17px' },
                                color: '#adafc4',
                                lineHeight: 1.2,
                            }}
                        >
                            {t(descriptionText)}
                        </Typography>
                        <Button
                            variant="contained"
                            size="large"
                            endIcon={<ArrowOutwardRoundedIcon />}
                            onClick={() => {
                                window.location.href = '/clubs#igrameeting';
                            }}
                            sx={{
                                // height: '33px',
                                marginTop: 4,
                                textTransform: 'none',
                                borderRadius: 2,
                                color: 'white',
                                backgroundColor: '#FF7720',
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: darken('#FF7720', 0.1),
                                },
                            }}
                        >
                            {t('BannerScheduleMeeting00')}
                        </Button>
                    </Box>
                </Grow>
            </Grid>

            {/* Lado derecho: Video */}
            <Grid item xs={12} md={5}>
                <Grow in={videoInView} timeout={3000}>
                    <Box
                        ref={videoRef}
                        sx={{
                            padding: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {videoSrc && (
                            <video
                                style={{
                                    borderRadius: borderRadiusVideo,
                                    width: '90%',
                                    maxWidth: '500px',
                                    height: 'auto',
                                }}
                                autoPlay
                                muted
                                loop
                                playsInline
                            >
                                <source src={videoSrc} type="video/mp4" />
                                {t("Tu navegador no soporta la etiqueta de video.")}
                            </video>
                        )}

                        {imageSrc && (
                            <img
                                style={{
                                    borderRadius: borderRadiusVideo,
                                    width: '100%',
                                    maxWidth: '500px',
                                    height: 'auto',
                                }}
                                src={imageSrc}
                                alt="Descripción de la imagen"
                            />
                        )}

                    </Box>
                </Grow>
            </Grid>
        </Grid>
    );
}