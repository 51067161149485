import React from 'react';
import Typography from '@mui/material/Typography';
import { COLORS, FONT_FAMILY } from '../constants/constants';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Divider, Grid, lighten } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import X from "@mui/icons-material/X"

export default function Footer() {
    const { t } = useTranslation()
    const navigate = useNavigate();

    return (
        <Grid container sx={{ width: '90%', maxWidth: '1450px', margin: 'auto', paddingTop: { xs: '100px', md: '150px' }, padding: 2, paddingBottom: 15 }}>
            <Divider style={{ marginBottom: 50 }} />
            <Grid item xs={12} sm={4} padding={3}>
                <Typography
                    component="div"
                    sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        fontWeight: 600,
                        fontFamily: FONT_FAMILY,
                        fontSize: '20px',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        navigate("/");
                        window.scrollTo(0, 0);
                    }}
                >
                    <Link to="/">
                        <img src="/images/icbWhite.svg" alt="iCoachBasketball" style={{ height: 42, marginRight: 10, paddingTop: 10 }} />
                    </Link>

                </Typography>

                <Typography sx={{
                    fontSize: { xs: '16px', md: '18px' },
                    marginTop: '5px',
                    color: '#98a7b0',
                    fontFamily: 'Arial',
                    textAlign: 'left',

                }}>
                    {t('Footer07')}
                </Typography>
            </Grid>

            {/* FEATURES */}
            <Grid item xs={12} sm={3} padding={3} style={{ textAlign: 'left' }}>
                <h3 style={{ fontFamily: 'Arial', color: '#dce4ec' }}>{t('Header02')}</h3>
                <p style={{ margin: '0 0 7px' }}><a href="/automated-practices" style={{ color: '#98a7b0', textDecoration: 'none' }}>{t('Header05')}</a></p>
                <p style={{ margin: '0 0 7px' }}><a href="/customized-practices" style={{ color: '#98a7b0', textDecoration: 'none' }}>{t('Header06')}</a></p>
                <p style={{ margin: '0 0 7px' }}><a href="/drills-library" style={{ color: '#98a7b0', textDecoration: 'none' }}>{t('Header07')}</a></p>
                <p style={{ margin: '0 0 7px' }}><a href="/plays-library" style={{ color: '#98a7b0', textDecoration: 'none' }}>{t('Header08')}</a></p>
                <p style={{ margin: '0 0 7px' }}><a href="/playbook-creator" style={{ color: '#98a7b0', textDecoration: 'none' }}>{t('Header09')}</a></p>
                <p style={{ margin: '0 0 7px' }}><a href="/attendance-tracker" style={{ color: '#98a7b0', textDecoration: 'none' }}>{t('Header10')}</a></p>
            </Grid>

            {/* ABOUT */}
            <Grid item xs={12} sm={3} padding={3} style={{ textAlign: 'left' }}>
                <h3 style={{ fontFamily: 'Arial', color: '#dce4ec' }}>{t('Footer00')}</h3>
                <p style={{ margin: '0 0 7px' }}><a href="/pricing" style={{ color: '#98a7b0', textDecoration: 'none' }}>{t('Footer01')}</a></p>
                <p style={{ margin: '0 0 7px' }}><a href="/clubs" style={{ color: '#98a7b0', textDecoration: 'none' }}>{t('Footer02')}</a></p>
                <p style={{ margin: '0 0 7px' }}><a href="/support" style={{ color: '#98a7b0', textDecoration: 'none' }}>{t('Footer03')}</a></p>
                <p style={{ margin: '0 0 7px' }}><a href="https://app.icoachbasketball.com/termsandconditions" style={{ color: '#98a7b0', textDecoration: 'none' }}>{t('Footer04')}</a></p>
            </Grid>



            <Grid item xs={12} sm={2} padding={3} style={{ textAlign: 'left' }}>
                <h3 style={{ fontFamily: 'Arial', color: '#dce4ec' }}>{t('Footer05')}</h3>
                <p style={{ margin: '0 0 7px' }}>
                    <a href="mailto:contact@icoachbasketball.com" style={{ color: '#98a7b0', textDecoration: 'none' }}>
                        {t('Footer06')}
                    </a>
                </p>
            </Grid>

            <Grid item xs={12} sm={6} padding={3} style={{ textAlign: 'left' }}>
                <Typography sx={{
                    fontSize: { xs: '14px', md: '14px' },
                    marginTop: '30px',
                    color: '#98a7b0',
                    fontFamily: 'Arial',
                    textAlign: 'left',
                }}>
                    {t('Footer08')}
                </Typography>

            </Grid>

            <Grid item xs={12} sm={6} padding={3} sx={{ textAlign: { xs: 'left', md: 'right' }, marginTop: 1 }}>
                <a href="https://www.x.com/icoachbasket" style={{ margin: '10px', backgroundColor: '#fff', borderRadius: '50%', width: '40px', height: '40px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', border: `2px solid ${lighten(COLORS.blueICB, 0.2)}`, }}>
                    <X style={{ color: 'black' }} />
                </a>
                <a href="https://instagram.com/icoachbasket" style={{ margin: '10px', backgroundColor: '#fff', borderRadius: '50%', width: '40px', height: '40px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', border: `2px solid ${lighten(COLORS.blueICB, 0.2)}`, }}>
                    <InstagramIcon style={{ color: '#C13584' }} />
                </a>
            </Grid>

        </Grid >
    );
}