import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import { IframeSupport } from '../components/IframeSupport';
import Header from '../components/Header';
import LanguageSwitcher from '../components/LanguageSwitcher';
export default function SupportPage() {
    return (
        <>
            <Layout>
                <Header />
                <IframeSupport />
                <Footer />
                <LanguageSwitcher />
            </Layout>
        </>
    );
}
