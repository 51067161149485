import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HTTPApi from "i18next-http-backend";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HTTPApi)
  .init({
    fallbackLng: "en",
    // debug: true, 
    backend: {
      // With this option, the translations are not cached in the browser
      requestOptions: {
        cache: "no-store"
      }
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18next;